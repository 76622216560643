import React from "react"

import { ResponsivePie } from '@nivo/pie'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
import addToMailchimp from 'gatsby-plugin-mailchimp'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import containerStyles from "../pages/custom.module.css"
import Share from './share'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.






class Mail extends React.Component{


  constructor(props) {
    super(props);
    this.state = {email: null};
  }


  handleChange = (event) => {
      this.setState({email: event.target.value});
  };
  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

  // 1. via `.then`
  _handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
    .then(data => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      alert("Subscribed");
      console.log(data)
    })
    .catch(() => {
      // unnecessary because Mailchimp only ever
      // returns a 200 status code
      // see below for how to handle errors
    })
  };


  render() {
    return (
      <div>
      Share your results:
        <Share url={"https://archetypes.jilecek.cz/"+this.props.best} name = {this.props.best}/>



        </div>
    )
  };

}


export default Mail
