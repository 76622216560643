
import React from "react"
import logo from '../assets/images/jj.png';
import Button from '@material-ui/core/Button';
import containerStyles from "../pages/custom.module.css"
class MoreB extends React.Component {
  render() {

    return (
      <div>

      <ul className="actions">
        <li >

          <a href="https://jilecek.itch.io/taotime" className="button" onClick="window.fbq('track', 'AddToWishlistTao')"><div  className={containerStyles.vert}> <div className={containerStyles.logo}><img   className={containerStyles.imag} src={logo} alt="" /></div><div>Buy now on itch.io</div></div></a>

        </li>
      </ul>
    </div>
    );
  }
}
export default MoreB;
