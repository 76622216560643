import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import steampic from '../assets/images/header_capsule.png'
// import steampic from '../assets/images/web1.gif'
//import steampic from '../assets/images/jung.jpg' // test od 02.05.2021
import steampic from '../assets/images/taotime.webp' // test od 23.02.2022, nahrazen horni po skoro roce, lol

import MoreB from '../components/moreB'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    minHeight: 480,
  },
  media: {
    height: 175,
    whiteSpace: "break-spaces",
  },
  break: {

    whiteSpace: "break-spaces",
  },
  aktion: {
    display: "contents",
  },
});

export default function MediaCardA() {
  const classes = useStyles();

  return (
    <a href="https://jilecek.itch.io/taotime">
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={steampic}
          title="TaoTime - balance your life"
        />
        <CardContent>
          <Typography className={classes.break} variant="body2" color="textSecondary" component="p">
          <div>
          <h3>PC dream tracker with Jungian archetypes</h3>
            TaoTime is a tool for speeding up the Jungian individuation process. It's a tool to track your dreams, mood, music, habits, daily journals and much more. You can see correlations between the values you track, so you can discover any hidden patterns in your life.
            </div>
            <CardActions className={classes.aktion}>

            <center>  <MoreB/></center>
            </CardActions>
          </Typography>
        </CardContent>
      </CardActionArea>

    </Card>
    </a>

  );
}
