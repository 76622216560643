import React from "react"


import Button from '@material-ui/core/Button';
import containerStyles from "../pages/custom.module.css"
import Share from './share'
import ImgMediaCard2 from '../components/taotimecard'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


import { createMedia } from "@artsy/fresnel"
import ImgMediaCard from '../components/card'
import MoreA from '../components/morea'


const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
})





class MyVideo extends React.Component{




  render() {






    return (
      <div>




                 

      </div>

    )
  };

}


export default MyVideo
