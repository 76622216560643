
import React from "react"
import logo from '../assets/images/mini.png';
import Button from '@material-ui/core/Button';
import containerStyles from "../pages/custom.module.css"
import book from '../assets/images/999.webp'

import ImgMediaCard from '../components/card'


class More extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.arch = props.arch;
  }

  render() {
    return (
        <div>
        <center>
          

          
                <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=" + this.arch} onClick="window.fbq('track', 'AddToWishlist')">
                <img src={book} width="100%" alt="" />
                </a>
                

        </center>
        <br/>
        </div>


    );
  }
}
export default More;
